import React from "react";

const GradientButton = ({ text }) => {
  return (
    <button
      type="button"
      className="relative inline-flex items-center gap-x-1.5 rounded-md bg-gradient-to-r from-gray-800 via-gray-700 to-canny-gray-800 border-white border px-3 md:px-6 py-2 md:py-3 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
    >
      {text}
    </button>
  );
};

export default GradientButton;
