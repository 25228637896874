import React from "react";
import { InlineWidget } from "react-calendly";
import Navbar from "../components/Navbar";
import headerImage from "../assets/images/headerImage-1.png";
import contentImage from "../assets/images/contentImage-1.png";
import GradientButton from "../components/GradientButton";
import Facebook from "../assets/images/Facebook.png";
import Google from "../assets/images/Google.png";
import Instagram from "../assets/images/Instagram.png";
import {
  BuildingOffice2Icon,
  EnvelopeIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";

const Home = () => {
  return (
    <>
      <Navbar />
      <main>
        <div className="relative isolate bg-gray-900">
          {/* <svg
            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V .5H200" fill="none" />
              </pattern>
            </defs>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
            />
          </svg> */}
          <div
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 grayscale"
              style={{
                clipPath:
                  "polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
              }}
            />
          </div>
          <div className="overflow-hidden">
            <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
              <div className="mx-auto max-w-2xl gap-x-0 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                  <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                    We’re changing the way people connect.
                  </h1>
                  <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                    Empowering connections through transformative digital
                    advertising. Welcome to a new era of engagement with Canny
                    Copywriting. Our expertise in Facebook Ads, Instagram Ads,
                    and Google Ads redefines how people connect and businesses
                    thrive.
                  </p>
                  <div className="mt-10 flex items-center gap-x-6">
                    <GradientButton text="Get Started" />
                    <a
                      href="#"
                      className="text-sm font-semibold leading-6 text-white"
                    >
                      Live demo <span aria-hidden="true">→</span>
                    </a>
                  </div>
                </div>
                <div className="mt-24 sm:mt-0">
                  <img
                    src={headerImage}
                    className="z-10 w-[600px]"
                    alt="App screenshot"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="px-6 lg:px-8 pb-28 bg-gray-900">
        {/* <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            We approach the workplace as something that adds to our lives and
            adds value to world.
          </h2>
          <p className="mt-6 text-base leading-7 text-gray-400">
            Diam nunc lacus lacus aliquam turpis enim. Eget hac velit est
            euismod lacus. Est non placerat nam arcu. Cras purus nibh cursus sit
            eu in id. Integer vel nibh.
          </p>
        </div> */}
        <div className="mx-auto grid grid-cols-1 md:grid-cols-3 grid-rows-3 md:grid-rows-1 flex-col gap-8 lg:mx-0 lg:flex-row lg:items-end">
          <div className="col-span-3 lg:col-span-1 flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-800 p-8 sm:flex-row-reverse sm:items-end lg:flex-none lg:flex-col lg:items-start lg:gap-y-12">
            <p className="flex-none text-6xl font-bold tracking-tight text-white">
              2
            </p>
            <div className="sm:shrink lg:flex-none">
              <p className="text-4xl font-semibold tracking-wide leading-tight text-white">
                Small Business Pricing
              </p>
              <p className="mt-2 text-base leading-7 text-gray-400">
                Start a partnership with no marked-up company overhead, or
                long-term contracts.
              </p>
            </div>
          </div>
          <div className="-order-1 md:order-none col-start-1 col-span-3 lg:col-span-1  flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gradient-to-br from-gray-800 via-gray-700 to-gray-800 p-8 sm:flex-row-reverse sm:items-end lg:flex-auto lg:flex-col lg:items-start lg:gap-y-16">
            <p className="flex-none text-7xl font-bold tracking-tight text-white">
              1
            </p>
            <div className="sm:shrink lg:flex-none">
              <p className="text-5xl font-semibold tracking-wide leading-snug text-white">
                Big Agency Quality
              </p>
              <p className="mt-2 text-base leading-7 text-gray-300">
                Get a professional copywriter with 15 years of experience and
                expertise at big digital agencies.
              </p>
            </div>
          </div>
          <div className="col-span-3 lg:col-span-1 flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-800 p-8 sm:flex-row-reverse sm:items-end  lg:flex-auto lg:flex-col lg:items-start lg:gap-y-10">
            <p className="flex-none text-5xl font-bold tracking-tight text-white">
              3
            </p>
            <div className="sm:shrink  lg:flex-none">
              <p className="text-3xl font-semibold tracking-wide text-white">
                Happiness Guaranteed
              </p>
              <p className="mt-2 text-base leading-7 text-indigo-200">
                No need to worry about getting burned by an unreliable
                freelancer.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-6 lg:px-8 py-16 bg-gray-900">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <img src={contentImage} className="w-full" />
          </div>
          <div className="flex items-center">
            <div>
              <h2 className="text-3xl font-bold tracking-wider text-white sm:text-4xl">
                Discover the power to show what you do best.
              </h2>
              <p className="mt-6 text-base leading-7 text-gray-400">
                You're already great at what you do. I'm here to write the words
                that show people why your business is the right one for the job.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-6 lg:px-8 py-10 bg-gray-900">
        <div className="mx-auto max-w-2xl text-center">
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Everything you need for marketing
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            We offer a full suite of marketing services to help you grow your
            business. We can help you with your website, social media, email and
            more. We also offer consulting services to help you with your
            marketing strategy.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-16 gap-y-16 lg:max-w-none lg:grid-cols-3">
            <div className="flex flex-col">
              <dt className="text-base font-semibold leading-7 text-white text-center flex flex-col justify-center items-center">
                <img
                  src={Facebook}
                  className="h-20 w-20 rounded-xl text-white"
                  aria-hidden="true"
                />
                Facebook Ads
              </dt>
              <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                <p className="flex-auto text-center">
                  Reach more customers on Facebook. We make ads that get
                  attention and bring you results.
                </p>
              </dd>
            </div>
            <div className="flex flex-col">
              <dt className="text-base font-semibold leading-7 text-white text-center flex flex-col justify-center items-center">
                <img
                  src={Instagram}
                  className="h-20 w-20 rounded-xl text-white"
                  aria-hidden="true"
                />
                Instagram Ads
              </dt>
              <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                <p className="flex-auto text-center">
                  Show off your brand on Instagram. Our cool ads help you get
                  noticed and connect with people.
                </p>
              </dd>
            </div>
            <div className="flex flex-col">
              <dt className="text-base font-semibold leading-7 text-white text-center flex flex-col justify-center items-center">
                <img
                  src={Google}
                  className="h-20 w-20 rounded-xl text-white"
                  aria-hidden="true"
                />
                Google Ads
              </dt>
              <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                <p className="flex-auto text-center">
                  Be the top choice on Google. We create ads that pop up when
                  people search, bringing you clicks and customers.
                </p>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="sm:px-6 lg:px-20 py-20 bg-gray-900 pt-32">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16 border shadow-2xl shadow-slate-900">
          <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Our Customers Love Us
          </h2>
          <p className="mx-auto mt-6 max-w-3xl text-lg leading-8 text-gray-300">
            I hired Canny Copyright to social media market a property for sale
            in Hawai'i. Within 1 week I received over 2,000 views, 2 serious
            inquiries and 1 written offer! Thank you Canny Copyright for keeping
            within our budget, a timely delivery and an instant positive impact
            !
          </p>
          <div className="mx-auto mt-20 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:max-w-4xl lg:grid-cols-5">
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/transistor-logo-white.svg"
              alt="Transistor"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/reform-logo-white.svg"
              alt="Reform"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/tuple-logo-white.svg"
              alt="Tuple"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/savvycal-logo-white.svg"
              alt="SavvyCal"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/statamic-logo-white.svg"
              alt="Statamic"
              width={158}
              height={48}
            />
          </div>
          <div
            className="absolute -top-24 right-0 -z-10 transform-gpu blur-3xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[1404/767] w-[87.75rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25 grayscale"
              style={{
                clipPath:
                  "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
              }}
            />
          </div>
        </div>
      </div>
      <div className="px-6 lg:px-8 py-28 bg-gray-900">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="md:h-full h-screen">
            <InlineWidget
              url="https://calendly.com/canny-copywriting/30min"
              styles={{ height: "100%", borderRadius: "8px" }}
            />
          </div>
          <div className="flex items-center md:py-32">
            <div>
              <h2 className="text-gray-300 tracking-wide text-lg font-bold mb-6">
                SCHEDULE YOUR CALL WITH CANNY COPYWRITING
              </h2>
              <h3 className="text-white trachink-wide text-5xl font-bold mb-14">
                Free 15-Minute Demo Call
              </h3>
              <p className="text-gray-300 tracking-wider leading-7 mb-6">
                By the end of this Audit call, you will have a clear
                understanding of the next steps you can take for your business
                to start generating consistent and reliable results online with
                Funnels & Paid Advertising.
              </p>
              <p className="text-gray-300 tracking-wider leading-7 mb-10">
                Find a time on Rex Baughman's calendar to schedule your call
                today and we look forward to speaking to you soon!
              </p>
              <h4 className="text-white text-lg font-bold tracking-wide">
                THIS AUDIT CALL IS PERFECT FOR:
              </h4>
              <ul className="list-disc mt-6">
                <li className="text-gray-300 mb-2">
                  Businesses looking to convert their current website into a
                  high quality & streamlined funnel format.
                </li>
                <li className="text-gray-300 mb-2">
                  ​Businesses looking to take their offline business online
                </li>
                <li className="text-gray-300 mb-2">
                  Businesses looking to understand their increased revenue
                  potential with funnels & conversion rate optimization.
                </li>
                <li className="text-gray-300 mb-2">
                  Businesses looking to maximize their conversion rates &
                  average order value.
                </li>
                <li className="text-gray-300 mb-2">
                  Businesses looking for a reliable agency that can make their
                  company a priority.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="px-6 lg:px-8 py-28 bg-gray-900">
        <div className="relative isolate bg-gray-900">
          <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
            <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
              <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden ring-1 ring-white/5 lg:w-1/2">
                  <svg
                    className="absolute inset-0 h-full w-full stroke-gray-700 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                    aria-hidden="true"
                  >
                    <defs>
                      <pattern
                        id="54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2"
                        width={200}
                        height={200}
                        x="100%"
                        y={-1}
                        patternUnits="userSpaceOnUse"
                      >
                        <path d="M130 200V.5M.5 .5H200" fill="none" />
                      </pattern>
                    </defs>
                    <svg
                      x="100%"
                      y={-1}
                      className="overflow-visible fill-gray-800/20"
                    >
                      <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                    </svg>
                    <rect
                      width="100%"
                      height="100%"
                      strokeWidth={0}
                      fill="url(#54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2)"
                    />
                  </svg>
                  <div
                    className="absolute -left-56 top-[calc(100%-13rem)] transform-gpu blur-3xl lg:left-[max(-14rem,calc(100%-59rem))] lg:top-[calc(50%-7rem)]"
                    aria-hidden="true"
                  >
                    <div
                      className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-br from-[#80caff] to-[#4f46e5] opacity-20 grayscale"
                      style={{
                        clipPath:
                          "polygon(74.1% 56.1%, 100% 38.6%, 97.5% 73.3%, 85.5% 100%, 80.7% 98.2%, 72.5% 67.7%, 60.2% 37.8%, 52.4% 32.2%, 47.5% 41.9%, 45.2% 65.8%, 27.5% 23.5%, 0.1% 35.4%, 17.9% 0.1%, 27.6% 23.5%, 76.1% 2.6%, 74.1% 56.1%)",
                      }}
                    />
                  </div>
                </div>
                <h2 className="text-3xl font-bold tracking-tight text-white">
                  Get in touch
                </h2>
                <p className="mt-6 text-lg leading-8 text-gray-300">
                  Cultivate your online presence with us. Reach out today and
                  let's start crafting compelling campaigns together. Your
                  success is just a message away. Connect with Us for
                  unparalleled expertise in Facebook Ads, Instagram Ads, and
                  Google Ads. Your digital journey begins here.
                </p>
                <dl className="mt-10 space-y-4 text-base leading-7 text-gray-300">
                  <div className="flex gap-x-4">
                    <dt className="flex-none">
                      <span className="sr-only">Address</span>
                      <BuildingOffice2Icon
                        className="h-7 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd>
                      545 Mavis Island
                      <br />
                      Chicago, IL 99191
                    </dd>
                  </div>
                  <div className="flex gap-x-4">
                    <dt className="flex-none">
                      <span className="sr-only">Telephone</span>
                      <PhoneIcon
                        className="h-7 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd>
                      <a
                        className="hover:text-white"
                        href="tel:+1 (555) 234-5678"
                      >
                        +1 (555) 234-5678
                      </a>
                    </dd>
                  </div>
                  <div className="flex gap-x-4">
                    <dt className="flex-none">
                      <span className="sr-only">Email</span>
                      <EnvelopeIcon
                        className="h-7 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd>
                      <a
                        className="hover:text-white"
                        href="mailto:hello@example.com"
                      >
                        hello@example.com
                      </a>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <form
              action="#"
              method="POST"
              className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48"
            >
              <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                  <div>
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-semibold leading-6 text-white"
                    >
                      First name
                    </label>
                    <div className="mt-2.5">
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        autoComplete="given-name"
                        className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-semibold leading-6 text-white"
                    >
                      Last name
                    </label>
                    <div className="mt-2.5">
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="email"
                      className="block text-sm font-semibold leading-6 text-white"
                    >
                      Email
                    </label>
                    <div className="mt-2.5">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="email"
                        className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="subject"
                      className="block text-sm font-semibold leading-6 text-white"
                    >
                      Subject
                    </label>
                    <div className="mt-2.5">
                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="message"
                      className="block text-sm font-semibold leading-6 text-white"
                    >
                      Message
                    </label>
                    <div className="mt-2.5">
                      <textarea
                        name="message"
                        id="message"
                        rows={4}
                        className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-8 flex justify-end">
                  <GradientButton text="Submit" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
